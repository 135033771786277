import { FormsTable } from 'components';
import { useAuth } from 'context';

export default function MyDrafts() {
  const { IS_INVOICE_ADMIN } = useAuth();

  return (
    <FormsTable
      invoiceable={IS_INVOICE_ADMIN}
      persistentListParametersStateKey='pendingProcessing'
      title='Pending Processing'
    />
  );
}
