import { Configuration as MSALConfiguration, PublicClientApplication } from '@azure/msal-browser';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { AuthProvider } from 'context';
import { SnackbarProvider } from 'notistack';
import {
  AddEditCustomer,
  AddEditInvoice,
  AddEditJob,
  AddEditViewForm,
  AllForms,
  Company,
  Customers,
  Equipment,
  Invoiced,
  Invoicing,
  Jobs,
  MyDrafts,
  MyForms,
  PendingProcessing,
  Processed
} from 'pages';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import AppLayout from './Layout';
import theme from './theme';
import { MsalProvider } from '@azure/msal-react';
import { ProtectedRoute } from 'components';

export const msalConfiguration: MSALConfiguration = {
  auth: {
    clientId: '11719855-fe74-4eb5-8b59-d66e524e6cab',
    authority: 'https://login.microsoftonline.com/1af5dee0-5c70-4293-99b2-b079042f0c57',
    navigateToLoginRequestUrl: true,
    //  redirectUri is where the user will be navigated to after successful login.
    //  If you change this, make sure the redirectUri is included in the Azure App Registration's Redirect URIs.
    //    Note: Alonza can update the app registration.
    redirectUri: '/my-forms'
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: true
  }
};

export const publicClientApplication = new PublicClientApplication(msalConfiguration);

export const scopes = [
  // 'https://graph.microsoft.com/.default',
  'Group.Read.All',
  'GroupMember.Read.All',
  'User.Read',
  'User.Read.All',
  'profile',
  'openid',
  'email'
];

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<AppLayout />}>
      <Route path='all-forms' element={<AllForms />} />
      <Route path='company/:companyId' element={<Company />} />
      <Route path='equipment' element={<Equipment />} />
      <Route path='forms/:formNumber' element={<AddEditViewForm />} />
      <Route path='invoiced' element={<Invoiced />} />
      <Route path='my-drafts' element={<MyDrafts />} />
      <Route path='my-forms' element={<MyForms />} />
      <Route path='pending-processing' element={<PendingProcessing />} />
      <Route path='processed' element={<Processed />} />

      <Route element={<ProtectedRoute IS_INVOICE_ADMIN />}>
        <Route path='customers' element={<Customers />} />
        <Route path='customers/:customerId' element={<AddEditCustomer />} />

        <Route path='invoicing' element={<Invoicing />} />
        <Route path='invoicing/:invoiceNumber' element={<AddEditInvoice />} />

        <Route path='jobs' element={<Jobs />} />
        <Route path='jobs/:jobNumber' element={<AddEditJob />} />
      </Route>
    </Route>
  )
);

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme} />
      <SnackbarProvider maxSnack={6} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <MsalProvider instance={publicClientApplication}>
          <AuthProvider>
            <CssBaseline />
            <RouterProvider router={router} />
          </AuthProvider>
        </MsalProvider>
      </SnackbarProvider>
    </StyledEngineProvider>
  );
};

export default App;
